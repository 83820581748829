<template>
  <div>
    <general-table
      ref="friendsTable"
      :api-url="APIURL"
      :columns="columns"
      :add-type="addType"
      :type="type"
      guard="configs"
    />
    <div>
      <div>
        <b-modal
          id="merchant"
          ref="genmodal"
          hide-footer
          centered
          size="lg"
          no-close-on-backdrop
          title="Configs"
        >
          <add-edit-configs />
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import AddEditConfigs from './AddEditConfigs.vue'

export default {
  components: {
    GeneralTable,
    AddEditConfigs,
  },
  data() {
    return {
      APIURL: 'configs',
      addType: 'Add',
      type: 'modal',
      columns: [
        { key: 'id', sortable: true },
        { key: 'name', label: 'Name' },
        { key: 'key', label: 'Key' },
        { key: 'group', label: 'Group' },
        { key: 'type', label: 'Type' },
        // { key: 'is_active', label: 'Active' },
        { key: 'actions' },
      ],
    }
  },

}
</script>

<style>

</style>
