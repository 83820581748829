<template>
  <div>
    <general-settings />
  </div>
</template>

<script>
import GeneralSettings from './general-configs/General.vue'

export default {
  components: { GeneralSettings },

}
</script>

<style>

</style>
