<template>
  <div>
    <validation-observer
      ref="infoRules"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Key"
            rules="required"
          >
            <b-form-group
              label="Key"
              label-for="key"
            >
              <b-form-input
                id="key"
                v-model="userForm.key"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Key"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Value"
            rules="required"
          >
            <b-form-group
              label="Value"
              label-for="Value"
            >
              <b-form-input
                id="Value"
                v-model="userForm.value"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Value"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Group"
            rules="required"
          >
            <b-form-group
              label="Group"
              label-for="Group"
            >
              <b-form-input
                id="Group"
                v-model="userForm.group"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Group"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="Name"
            >
              <b-form-input
                id="Name"
                v-model="userForm.name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            name="Type"
            rules="required"
          >
            <b-form-group
              label="Type"
              label-for="group"
            >
              <v-select
                id="type"
                v-model="userForm.type"
                label="text"
                :reduce="type => type.value"
                :options="inputType"
                placeholder="Select Type"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            name="Description"
            rules="required"
          >
            <b-form-group
              label="Description"
              label-for="Group"
            >
              <quill-editor
                v-model="userForm.description"
                :options="snowOption"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="12">
          <v-jsoneditor
            v-model="userForm.meta"
            :options="options"
            :plus="false"
            height="400px"
          /></b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader"
            variant="primary"
            class="mr-1"
            @click="addConfig()"
          >
            Save
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeModal()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import { required } from '@validations'
import previewImage from '@/global-compositions/previewImage'

export default {
  components: { vSelect, quillEditor },
  mixins: [previewImage],
  data() {
    return {
      required,
      userForm: {},
      merchantType: [],
      users: [],
      owners: [],
      id: this.$store.state.generalIds.id,
      validationErrors: {
        user: '',
        phone_number: '',
        full_name: '',
      },
      userID: '',
      ownerId: '',
      loader: false,
      snowOption: {
        modules: {
          toolbar: [
            ['link'],
          ],
        },
      },
    }
  },
  setup() {
    const { getValidationState } = formValidation()

    const userForm = ref({
      key: '',
      value: '',
      group: '',
      name: '',
      type: '',
      description: '',
      meta: '',
    })

    const inputType = [
      {
        text: 'Text',
        value: 'text',
      },
      {
        text: 'File',
        value: 'file',
      },
      {
        text: 'Number',
        value: 'number',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Password',
        value: 'password',
      },
      {
        text: 'Select',
        value: 'select',
      },
      {
        text: 'Checkbox',
        value: 'checkbox',
      },
      {
        text: 'Editor',
        value: 'editor',
      },
      {
        text: 'Color',
        value: 'color',
      },
    ]

    const options = {
      mode: 'code',
    }

    return {
      getValidationState,
      userForm,
      inputType,
      options,
    }
  },
  mounted() {
    this.viewConfig()
  },
  methods: {
    closeModal() {
      this.userForm = {
        key: '',
        value: '',
        group: '',
        name: '',
        type: '',
        description: '',
        meta: '',
      }
      this.$store.commit('generalIds/SET_ID', null)
      this.$bvModal.hide('merchant')
    },
    addConfig() {
      if (this.id) {
        axios.post(`configs/${this.id}`, this.userForm).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$parent.$parent.$parent.$refs.friendsTable.$refs.productsTable.refresh()
            this.closeModal()
            this.$store.commit('generalIds/SET_ID', null)
            this.$bvModal.hide('merchant')
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        axios.post('configs', this.userForm).then(res => {
          this.$parent.$parent.$parent.$refs.friendsTable.$refs.productsTable.refresh()
          if (res.status === 200 || res.status === 201) {
            this.isEmpty(this.userForm)
            this.$bvModal.hide('merchant')
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
    viewConfig() {
      if (this.id) {
        axios.get(`configs/${this.id}`).then(res => {
          this.userForm = res.data?.data
        })
      }
    },
  },

}
</script>

<style lang="scss">
textarea#Meta {
    background-color: #202327;
    color: #89ce89;
}
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
